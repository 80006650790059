import styled from 'styled-components';

import { AutoLayout } from '@digigear/elements';

const Content = styled(AutoLayout).attrs({
  direction: 'column',
})`
  max-width: ${props => props.theme.dimensions.pageWidth};

  margin-left: auto;
  margin-right: auto;

  box-sizing: border-box;
  padding-top: 120px;
  padding-bottom: 120px;

  height: 100%;

  position: relative;

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export default Content;
