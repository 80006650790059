import React from 'react';
import styled from 'styled-components';

import Content from './Content';
import Section from './Section';

import { AutoLayout } from '@digigear/elements';

import button_1 from '../images/nailoong/section_3_button_1.png';
import details_1 from '../images/nailoong/section_6_details_1.png';
import details_2 from '../images/nailoong/section_6_details_2.png';
import details_3 from '../images/nailoong/section_6_details_3.png';
import details_4 from '../images/nailoong/section_6_details_4.png';
import Button from './Button';

const StyledSection7 = styled(Section)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  padding: 0;

  ${Content} {
    background: ${props => props.theme.palette.pink.main};
    width: 100%;
    max-width: none;
    /* margin: 0; */
    padding: 80px 40px;
    color: white;

    & h2 {
      color: ${props => props.theme.palette.secondary.main};
    }

    & p {
      font-weight: 300;
      font-size: 19px;

      &.p-small {
        font-size: 12px;
      }
    }

    & .responsive-show {
      display: none;
    }

    @media screen and (max-width: 800px) {
      & .responsive-layout {
        flex-direction: column;
        margin-top: 24px;
        align-items: center;
      }

      & .responsive-hidden {
        display: none;
      }

      & .responsive-show {
        display: initial;
      }
    }
  }
`;

const StyledBlock = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 80vw;
  border-radius: 12px;
  padding: 12px 24px;

  background: ${props => props.theme.palette.pink.dark};
  color: white;
`;

const Section7 = () => {
  return (
    <StyledSection7>
      <Content alignItems="center">
        <h2>4KM跑（個人組）</h2>
        <img
          className="responsive-hidden"
          alt="details_1"
          src={details_1}
          style={{
            maxWidth: '80vw',
          }}
        />
        <img
          className="responsive-show"
          alt="details_2"
          src={details_2}
          style={{
            maxWidth: '80vw',
          }}
        />
        <AutoLayout
          className="responsive-layout"
          direction="row"
          gap={24}
          alignItems="top"
        >
          <StyledBlock>
            <p>
              4公里個人組基本跑手包內容:
              <br />
              <br />
              1. 奶龍個人組T-shirt x1
              <br />
              2. 奶龍毛巾 x1
              <br />
              3. 奶龍號碼布 x1
              <br />
              4. 奶龍完賽獎牌 x1 (完成賽事後派發)
            </p>
          </StyledBlock>
          <Button
            href="https://www.klook.com/zh-HK/activity/112636-nailong-run/"
            rel="noreferrer"
            target="_blank"
            style={{
              background: 'transparent',
            }}
          >
            <img
              alt="button_1"
              src={button_1}
              style={{
                width: '300px',
                maxWidth: '80vw',
              }}
            />
          </Button>
        </AutoLayout>

        <div
          style={{
            color: 'white',
            maxWidth: '80%',
            fontSize: '10px',
            fontWeight: 300,
            textAlign: 'center',
          }}
        >
          <p className="p-small">
            *12歲以下人士不能參加4公里之賽事
            <br />
            *18歲以下之參賽者，必須獲得家長或監護人同意方可報名。
            <br />
            <br />
            活動當日，每位參賽者可憑號碼布於行李寄存區寄存行李。
            <br />
            所有完成賽事的跑手均可獲得電子證書及獎牌1個(活動當日領取)
          </p>
        </div>
      </Content>

      <Content alignItems="center">
        <h2>4KM跑（朋友組）</h2>
        <img
          className="responsive-hidden"
          alt="details_3"
          src={details_3}
          style={{
            maxWidth: '80vw',
          }}
        />
        <img
          className="responsive-show"
          alt="details_4"
          src={details_4}
          style={{
            maxWidth: '80vw',
          }}
        />
        <AutoLayout
          className="responsive-layout"
          direction="row"
          gap={24}
          alignItems="top"
        >
          <StyledBlock>
            <AutoLayout direction="row" gap={12}>
              <p>
                4公里朋友組(2人)
                <br />
                基本跑手包內容:
                <br />
                <br />
                1. 奶龍朋友組T-shirt x2
                <br />
                2. 奶龍毛巾 x2
                <br />
                3. 奶龍號碼布 x2
                <br />
                4. 奶龍完賽獎牌 x2 (完成賽事後派發)
              </p>

              <p>
                4公里朋友組(3人)
                <br />
                基本跑手包內容:
                <br />
                <br />
                1. 奶龍朋友組T-shirt x3
                <br />
                2. 奶龍毛巾 x3
                <br />
                3. 奶龍號碼布 x3
                <br />
                4. 奶龍完賽獎牌 x3 (完成賽事後派發)
              </p>
            </AutoLayout>
          </StyledBlock>
          <Button
            href="https://www.klook.com/zh-HK/activity/112636-nailong-run/"
            rel="noreferrer"
            target="_blank"
            style={{
              background: 'transparent',
            }}
          >
            <img
              alt="button_1"
              src={button_1}
              style={{
                width: '300px',
                maxWidth: '80vw',
              }}
            />
          </Button>
        </AutoLayout>
        <div
          style={{
            color: 'white',
            maxWidth: '80%',
            fontSize: '10px',
            fontWeight: 300,
            textAlign: 'center',
          }}
        >
          <p className="p-small">
            *12歲以下人士不能參加4公里之賽事
            <br />
            *18歲以下之參賽者，必須獲得家長或監護人同意方可報名。
            <br />
            <br />
            活動當日，每位參賽者可憑號碼布於行李寄存區寄存行李。
            <br />
            所有完成賽事的跑手均可獲得電子證書及獎牌1個(活動當日領取)
          </p>
        </div>
      </Content>
    </StyledSection7>
  );
};

export default Section7;
