import React from 'react';
import styled from 'styled-components';

import Content from './Content';
import Section from './Section';

import { AutoLayout } from '@digigear/elements';

import details_1 from '../images/nailoong/section_9_details_1.png';
import details_2 from '../images/nailoong/section_9_details_2.png';
import item_1 from '../images/nailoong/section_9_item_1.png';
import item_2 from '../images/nailoong/section_9_item_2.png';
import item_3 from '../images/nailoong/section_9_item_3.png';
import item_4 from '../images/nailoong/section_9_item_4.png';

const StyledSection9 = styled(Section)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  padding: 0;

  ${Content} {
    background: ${props => props.theme.palette.pink.main};
    width: 100%;
    max-width: none;
    /* margin: 0; */
    padding: 80px 40px;
    color: ${props => props.theme.palette.secondary.main};

    & .responsive-show {
      display: none;
    }

    @media screen and (max-width: 800px) {
      & .responsive-layout {
        flex-direction: column;
        /* margin-top: 24px; */
      }

      & .responsive-hidden {
        display: none;
      }

      & .responsive-show {
        display: initial;
      }
    }
  }
`;

const Section9 = () => {
  return (
    <StyledSection9>
      <Content alignItems="center">
        <h2>額外加購 Set A/B/C/D</h2>
        <AutoLayout direction="column" gap={24} alignItems="center">
          <AutoLayout
            className="responsive-layout"
            direction="row"
            gap={24}
            alignItems="center"
          >
            <img
              alt="item_1"
              src={item_1}
              style={{
                width: '390px',
                maxWidth: '90vw',
              }}
            />
            <img
              alt="item_2"
              src={item_2}
              style={{
                width: '390px',
                maxWidth: '90vw',
              }}
            />
          </AutoLayout>
          <AutoLayout
            className="responsive-layout"
            direction="row"
            gap={24}
            alignItems="center"
          >
            <img
              alt="item_3"
              src={item_3}
              style={{
                width: '478px',
                maxWidth: '90vw',
              }}
            />
            <img
              alt="item_4"
              src={item_4}
              style={{
                width: '316px',
                maxWidth: '90vw',
              }}
            />
          </AutoLayout>
        </AutoLayout>
        <img
          className="responsive-hidden"
          alt="details_1"
          src={details_1}
          style={{
            width: '100%',
            maxWidth: '1080px',
          }}
        />
        <img
          className="responsive-show"
          alt="details_2"
          src={details_2}
          style={{
            width: '100%',
          }}
        />
      </Content>
    </StyledSection9>
  );
};

export default Section9;
