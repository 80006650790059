import React from 'react';
import styled from 'styled-components';

import { AutoLayout } from '@digigear/elements';
import featureBg from '../images/nailoong/section_5.png';
import Content from './Content';
import Section from './Section';

import button_1 from '../images/nailoong/section_5_button_1.png';
import details_1 from '../images/nailoong/section_5_details_1.png';
import details_2 from '../images/nailoong/section_5_details_2.png';
import Button from './Button';

const StyledSection5 = styled(Section)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  padding: 0;

  ${Content} {
    margin-top: -1.8em;
    background: ${props => props.theme.palette.secondary.main};
    width: 100%;
    max-width: none;
    /* margin: 0; */
    padding: 80px 40px;
    color: ${props => props.theme.palette.primary.main};

    & h3 {
      margin: 0;
    }

    & h4 {
      font-weight: 500;
      font-size: 25px;
    }

    & p {
      font-weight: 300;
      font-size: 19px;

      &.p-small {
        font-size: 12px;
      }
    }

    & .responsive-show {
      display: none;
    }

    @media screen and (max-width: 800px) {
      & .responsive-layout {
        flex-direction: column;
        margin-top: 24px;
        align-items: center;
      }
      & .responsive-hidden {
        display: none;
      }

      & .responsive-show {
        display: initial;
      }
    }
  }
`;

const StyledBlock = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 80vw;
  border-radius: 12px;
  padding: 12px 24px;

  background: #102574;
  color: white;
`;

const Section5 = () => {
  return (
    <StyledSection5>
      <img
        alt="hero"
        src={featureBg}
        style={{
          width: '100%',

          maxWidth: 'unset',
          zIndex: 2,
        }}
      />
      <Content alignItems="center">
        <img
          className="responsive-hidden"
          alt="details_1"
          src={details_1}
          style={{
            maxWidth: '80vw',
          }}
        />
        <img
          className="responsive-show"
          alt="details_2"
          src={details_2}
          style={{
            maxWidth: '80vw',
          }}
        />
        <AutoLayout
          className="responsive-layout"
          direction="row"
          gap={24}
          alignItems="top"
        >
          <StyledBlock>
            <p>
              1公里水槍探險戰基本跑手包內容:
              <br />
              <br />
              1. 奶龍消暑限定水槍背囊 x1
              <br />
              2. 奶龍消暑限定變色T-shirt x1
              <br />
              3. 奶龍毛巾 x1
              <br />
              4. 奶龍號碼布 x1
              <br />
              5. 奶龍完賽獎牌 x1 (完成賽事後派發)
            </p>
          </StyledBlock>

          <Button
            href="https://www.klook.com/zh-HK/activity/112636-nailong-run/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt="button_1"
              src={button_1}
              style={{
                width: '300px',
                maxWidth: '80vw',
              }}
            />
          </Button>
        </AutoLayout>
        <div
          style={{
            color: 'white',
            maxWidth: '80%',
            fontSize: '10px',
            fontWeight: 300,
            textAlign: 'center',
          }}
        >
          <p className="p-small">
            *3-11歲之1KM水槍探險戰參加者必須由成人陪同下方可進入跑道及參與賽事
            <br />
            *18歲以下之參賽者，必須獲得家長或監護人同意方可報名。
            <br />
            <br />
            活動當日，每位參賽者可憑號碼布於行李寄存區寄存行李。
            <br />
            所有完成賽事的跑手均可獲得電子證書及獎牌1個(活動當日領取)
          </p>
        </div>
      </Content>
    </StyledSection5>
  );
};

export default Section5;
