import React from 'react';
import styled from 'styled-components';

import Content from './Content';
import Section from './Section';

import { AutoLayout } from '@digigear/elements';

import details_1 from '../images/nailoong/section_4_details_1.png';
import details_2 from '../images/nailoong/section_4_details_2.png';
import details_3 from '../images/nailoong/section_4_details_3.png';
import details_4 from '../images/nailoong/section_4_details_4.png';
import details_5 from '../images/nailoong/section_4_details_5.png';
import details_6 from '../images/nailoong/section_4_details_6.png';
import details_7 from '../images/nailoong/section_4_details_7.png';
import details_8 from '../images/nailoong/section_4_details_8.png';
import details_9 from '../images/nailoong/section_4_details_9.png';
import details_10 from '../images/nailoong/section_4_details_10.png';
import details_11 from '../images/nailoong/section_4_details_11.png';
import details_12 from '../images/nailoong/section_4_details_12.png';
import details_13 from '../images/nailoong/section_4_details_13.png';
import details_14 from '../images/nailoong/section_4_details_14.png';
import section4_1 from '../images/nailoong/section_4.1.png';

const StyledSection4 = styled(Section)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  padding: 0;

  background: ${props => props.theme.palette.primary.main};

  ${Content} {
    background: ${props => props.theme.palette.primary.main};
    width: 100%;
    max-width: none;
    /* margin: 0; */
    padding: 80px 40px;
    color: white;

    & .vl {
      border-left: 1px solid white;
    }

    & .responsive-show {
      display: none;
    }

    @media screen and (max-width: 800px) {
      & .responsive-layout {
        flex-direction: column;
        margin-top: 24px;
        gap: 24px;
        justify-items: none;
        align-items: center;
      }

      & .responsive-reverse {
        flex-direction: column-reverse;
        gap: 24px;
        justify-items: none;
      }

      & .responsive-hidden {
        display: none;
      }

      & .responsive-show {
        display: initial;
      }
    }
  }
`;

const Section4 = () => {
  return (
    <StyledSection4>
      <Content alignItems="center">
        <h2>會場地圖</h2>
        <AutoLayout
          className="responsive-layout"
          direction="row"
          gap={24}
          alignItems="top"
          justifyContent="center"
          style={{
            maxWidth: '1000px',
            width: '100%',
          }}
        >
          <img
            className="responsive-hidden"
            alt="details_2"
            src={details_2}
            style={{
              width: '74px',
              maxWidth: '80vw',
            }}
          />

          <img
            className="responsive-show"
            alt="details_13"
            src={details_13}
            style={{
              width: '356px',
              maxWidth: '80vw',
            }}
          />
          <img
            alt="details_1"
            src={details_1}
            style={{
              width: '646px',
              aspectRatio: 1,
              maxWidth: '80vw',
            }}
          />
        </AutoLayout>
        <AutoLayout
          className="responsive-layout"
          direction="row"
          gap={24}
          alignItems="top"
          justifyContent="center"
          style={{
            maxWidth: '1000px',
            width: '100%',
          }}
        >
          <img
            className="responsive-hidden"
            alt="details_4"
            src={details_4}
            style={{
              width: '74px',
              height: '100%',
              maxWidth: '80vw',
              marginTop: '11em',
            }}
          />
          <img
            alt="details_3"
            src={details_3}
            style={{
              width: '646px',
              aspectRatio: 1,
              maxWidth: '80vw',
            }}
          />
        </AutoLayout>
      </Content>
      <img
        alt="hero"
        src={details_5}
        style={{
          width: '100%',

          maxWidth: 'unset',
          zIndex: 2,
        }}
      />
      <Content alignItems="center" style={{ marginTop: '-4.5em' }}>
        <AutoLayout direction="column" gap={24}>
          <AutoLayout
            className="responsive-layout"
            direction="column"
            gap={24}
            style={{
              maxWidth: '1000px',
              width: '100%',
            }}
          >
            <AutoLayout
              className="responsive-layout responsive-reverse"
              direction="row"
              justifyContent="space-between"
            >
              <AutoLayout
                gap={12}
                direction="row"
                alignItems="top"
                style={{ height: '100%' }}
              >
                <h3>1KM路線圖</h3>
                <div class="vl" />
                <p style={{ margin: 0 }}>
                  13:45 A組開跑
                  <br />
                  14:15 B組開跑
                  <br />
                  14:45 C組開跑
                </p>
              </AutoLayout>
              <img
                alt="details_6"
                src={details_6}
                style={{
                  width: '313px',
                  maxWidth: '80vw',
                }}
              />
            </AutoLayout>
            <img
              alt="details_7"
              src={details_7}
              style={{
                width: '100%',
                maxWidth: '80vw',
              }}
            />
          </AutoLayout>
          {/* coppppy */}
          <AutoLayout
            className="responsive-layout"
            direction="column"
            gap={24}
            style={{
              maxWidth: '1000px',
              width: '100%',
            }}
          >
            <AutoLayout
              className="responsive-layout responsive-reverse"
              direction="row"
              justifyContent="space-between"
            >
              <AutoLayout
                gap={12}
                direction="row"
                alignItems="top"
                style={{ height: '100%' }}
              >
                <h3>2KM路線圖</h3>
                <div class="vl" />
                <p style={{ margin: 0 }}>
                  11:30 個人A組開跑
                  <br />
                  12:05 個人B組開跑
                  <br />
                  12:35 朋友C組開跑
                  <br />
                  13:10 朋友D組開跑
                </p>
              </AutoLayout>
              <img
                alt="details_8"
                src={details_8}
                style={{
                  width: '235px',
                  maxWidth: '80vw',
                }}
              />
            </AutoLayout>
            <img
              alt="details_9"
              src={details_9}
              style={{
                width: '100%',
                maxWidth: '80vw',
              }}
            />
          </AutoLayout>
          {/* coppppy */}
          <AutoLayout
            className="responsive-layout"
            direction="column"
            gap={24}
            style={{
              maxWidth: '1000px',
              width: '100%',
            }}
          >
            <AutoLayout
              className="responsive-layout responsive-reverse"
              direction="row"
              justifyContent="space-between"
            >
              <AutoLayout
                gap={12}
                direction="row"
                alignItems="top"
                style={{ height: '100%' }}
              >
                <h3>4KM路線圖</h3>
                <div class="vl" />
                <p style={{ margin: 0 }}>
                  09:30 個人A組開跑
                  <br />
                  10:30 朋友B組開跑
                </p>
              </AutoLayout>
              <img
                alt="details_10"
                src={details_10}
                style={{
                  width: '477px',
                  maxWidth: '80vw',
                }}
              />
            </AutoLayout>
            <img
              alt="details_11"
              src={details_11}
              style={{
                width: '100%',
                maxWidth: '80vw',
              }}
            />
          </AutoLayout>
        </AutoLayout>
      </Content>
      <img
        alt="hero2"
        src={details_12}
        style={{
          width: '100%',

          maxWidth: 'unset',
          zIndex: 2,
        }}
      />
      <img
        alt="hero3"
        src={section4_1}
        style={{
          width: '100%',

          maxWidth: 'unset',
          zIndex: 2,
        }}
      />
    </StyledSection4>
  );
};

export default Section4;
