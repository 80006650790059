import React from 'react';
import styled from 'styled-components';
import Button from './Button';

import featureBg from '../images/nailoong/section_10.jpeg';

import details_1 from '../images/nailoong/section_10_details_1.png';

const StyledSection10 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  background: ${props => props.theme.palette.pink.main};

  p {
    font-weight: 300;
    font-size: 12px;
    margin-top: 0;
    padding: 0 7.5vw;
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
  border-radius: 0;
  background: none;
`;

const Section10 = () => {
  return (
    <StyledSection10>
      <img
        alt="section10"
        src={featureBg}
        style={{
          width: '100%',

          maxWidth: 'unset',
        }}
      />
      <StyledButton
        href="https://www.boclife.com.hk/tc/liveyoung/home.html"
        rel="noreferrer"
        target="_blank"
      >
        <img
          alt="details_1"
          src={details_1}
          style={{
            width: '100%',

            maxWidth: 'unset',
          }}
        />
      </StyledButton>
      <p>
        ^攤位遊戲細則請於活動當天向工作人員查詢，禮品數量有限，先到先得，送完即止。
        <br />
        *受條款及細則約束，詳情請瀏覽
        <a href="https://bit.ly/4cHSQKu" rel="noreferrer" target="_blank">
          <span>https://bit.ly/4cHSQKu</span>
        </a>
      </p>
    </StyledSection10>
  );
};

export default Section10;
