import React from 'react';
import styled from 'styled-components';

import featureBg from '../images/nailoong/header.png';

const StyledHeroSection2 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: hidden;
`;

const HeroSection2 = () => {
  return (
    <StyledHeroSection2>
      <img
        alt="hero"
        src={featureBg}
        style={{
          width: '100%',

          maxWidth: 'unset',
        }}
      />
    </StyledHeroSection2>
  );
};

export default HeroSection2;
