import React from 'react';
import styled from 'styled-components';

import Content from './Content';
import Section from './Section';

import { AutoLayout } from '@digigear/elements';

import details_1 from '../images/nailoong/section_8_details_1.png';
import details_2 from '../images/nailoong/section_8_details_2.png';

const StyledSection8 = styled(Section)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  padding: 0;

  ${Content} {
    background: ${props => props.theme.palette.secondary.main};
    width: 100%;
    max-width: none;
    /* margin: 0; */
    padding: 80px 40px;
    color: white;

    & > h2 {
      color: ${props => props.theme.palette.primary.main};
    }

    & span {
      font-weight: 300;
      font-size: 28px;
    }

    & > p {
      font-weight: 300;
      font-size: 14px;
    }

    @media screen and (max-width: 800px) {
      & .responsive-layout {
        flex-direction: column;
        /* margin-top: 24px; */
        align-items: center;
      }
    }
  }
`;

const StyledTitle = styled.div`
  border: 1px solid white;
  padding: 6px 12px;
  width: min-content;

  & > h3 {
    width: min-content;
    word-break: keep-all;
    margin: 0;
  }
`;

const Section8 = () => {
  return (
    <StyledSection8>
      <Content alignItems="center">
        <h2>早鳥優惠及加購詳情</h2>

        <AutoLayout
          className="responsive-layout"
          direction="row"
          gap={24}
          alignItems="top"
          justifyContent="center"
          style={{
            width: '80%',
          }}
        >
          <div style={{ minWidth: '50%' }}>
            <StyledTitle>
              <h3>早鳥優惠</h3>
            </StyledTitle>
            <p>
              參賽者凡於 <span>4月12號</span>早上10:00
              <br />
              <span>至4月14號</span>晚上11:59
              <br />
              <br />
              成功報名後，
              <br />
              均可獲得 <span>奶龍公仔鎖匙扣 </span> 一隻。
            </p>
          </div>
          <img
            alt=" details_1"
            src={details_1}
            style={{
              width: '180px',
              maxWidth: '80vw',
            }}
          />
        </AutoLayout>
        <AutoLayout
          className="responsive-layout"
          direction="row"
          gap={24}
          alignItems="top"
          justifyContent="center"
          style={{
            width: '80%',
          }}
        >
          <div>
            <StyledTitle>
              <h3>加購詳情</h3>
            </StyledTitle>

            <p>
              除了基本的跑手套裝外，
              <br />
              參加者可於報名網站額外加購4套活動限定套裝：
              <br />
              <br />
              加購 <span>Set A: 奶龍隨身套裝</span> 價錢為: <span>HK$78</span>
              <br />
              加購 <span>Set B: 奶龍水戰套裝</span> 價錢為: <span>HK$168</span>
              <br />
              加購 <span>Set C: 奶龍沙灘套裝</span> 價錢為: <span>HK$228</span>
              <br />
              加購 <span>Set D: 奶龍消暑濕水套裝</span> 價錢為:{' '}
              <span>HK$188</span>
            </p>
          </div>
          <img
            alt=" details_2"
            src={details_2}
            style={{
              width: '300px',
              height: '312px',
              maxWidth: '80vw',
            }}
          />
        </AutoLayout>
      </Content>
    </StyledSection8>
  );
};

export default Section8;
