import React from 'react';
import styled from 'styled-components';

import featureBg from '../images/nailoong/section_13.png';
import Section from './Section';

const StyledSection13 = styled(Section)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  padding: 0;
`;

const Section13 = () => {
  return (
    <StyledSection13>
      <img
        alt="hero"
        src={featureBg}
        style={{
          width: '100%',

          maxWidth: 'unset',
        }}
      />
    </StyledSection13>
  );
};

export default Section13;
