import React from 'react';
import styled from 'styled-components';

import Content from './Content';
import Section from './Section';

const StyledSection12 = styled(Section)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  padding: 0;

  ${Content} {
    background: ${props => props.theme.palette.primary.main};
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 80px 40px;
    color: white;

    & > * {
      width: 100%;
      text-align: left;
    }

    & > h2 {
      font-weight: 500;
      font-size: 38px;
    }

    & > h3 {
      font-weight: 500;
      font-size: 24px;
    }

    & > p {
      font-weight: 300;
      font-size: 17px;
    }
  }
`;

const Section12 = () => {
  return (
    <StyledSection12>
      <Content alignItems="center">
        <h2>注意事項</h2>
        <h3>條款及細則</h3>
        <h3>報名細則</h3>
        <p>
          1.
          當完成報名程序後，售票系統將即時向每名跑手發出確認電郵，以確認報名成功，電郵內會列明參加者的個人資料及其參加之組別。
          <br />
          2.
          請細心檢查資料，如發現任何資料上的出錯，請立即發送電郵至nailoongrun2024@knighthoodevent.com，並附上其確認電郵以便主辦方核對資料。
          <br />
          3.
          若於報名後7日內仍未收到確認電郵，參加者可透過售票系統的網上在線服務查詢，或到大會的Facebook
          Page 私訊查詢。
          <br />
          4.
          參賽者必須仔細閱讀活動條款、細則及安排。一經報名後，即代表參賽者接受及同意遵守主辦單位在此及其後引入之一切事項。
          <br />
          5. 參賽者必須確保在報名表格上之資料正確無誤。
          <br />
          6.
          活動屬自願參與性質，參賽者需自行承擔一切風險及責任，參賽者無權向主辦單位丶贊助商及相關支持機構追討任何形式的損失索償或追究責任(包括往返活動場地途中、活動期間發生的意外或引致之自身受傷、意外或死亡)。
          <br />
          7. 18歲以下之參賽者，必須獲得家長或監護人同意方可報名。
          <br />
          8.
          基於安全理由，請遵照大會於主頁提及的年齡規限。如有發現違反、大會有權取消該人士的參賽資格，且不獲退還所有報名款項。
        </p>
        <h3>更改報名資料</h3>
        <p>
          1.
          所有參賽者成功報名後均不可退款，重複報名者將不獲發還重複報名的費用。參賽者不得讓他人使用其號碼布/活動名額，如經發現，大會有權取消該參賽者及代跑者的參賽資格。
          <br />
          2.
          參賽者如需更改任何報名資料，請以電郵形式通知主辦方，根據情況主辦方有權按次收取行政費HK$50。
          <br />
          3. T恤尺碼不設更換，請參考尺碼表及慎重選擇。
        </p>
        <h3>賽事規則</h3>
        <p>
          1.
          參賽者必須確保身體健康狀況適合參加是次比賽/活動。主辦方在合理懷疑的情況下，活動場內的工作人員、醫務人員、賽事總監或保安均有權要求參賽者退出是次比賽/活動，及取消該参賽者的參賽資格，而不獲退還報名款項。
          <br />
          2.
          參賽者在比賽/活動期間，因蓄意破壞活動場地內的周邊佈景而導致主辦方需進行維修，參賽者需向主辦方作出一切賠償及損失，主辦方保留一切追究責任之權利。
          <br />
          3.
          參賽者如觸犯、違反或不遵守大會的任何守則，主辦單位有權取消其參賽資格及成績。
          <br />
          4. 主辦方有權更改大會條款及細則。如有任何爭議，主辦方保留最終決定權。
          <br />
          5.
          活動當日設有免費行李寄存服務，請參賽者避免攜帶大件行李及貴重物品，及看管好自己的財物，如有任何損失，主辦方不負任何責任。
          <br />
          6. 3-11歲之1KM水槍探險戰參加者必須由成人陪同下方可進入跑道及參與賽事
          <br />
          7. 5歲以下人士不能參加2公里之賽事，
          而12歲以下參賽者必須由成人陪同下方可進入跑道及參與賽事
          <br />
          8. 12歲以下人士不能參加4公里之賽事
          <br />
          9. 18歲以下之參賽者，必須獲得家長或監護人同意方可報名。
        </p>

        <h3>個人資料的使用</h3>

        <p>
          1.
          主辦方及其代理商有權使用參賽者的聯絡資料用作任何法例規定、授權或准許的合法用途(包括：有關活動之相片、錄像及賽事紀錄)。
          <br />
          2. 主辦單位與其委託的服務供應商 (例如:
          報名網站)，將有權使用參賽者所提供的資料作以下用途：
          <br />
          　　a. 大會公佈成績；
          <br />
          　　b. 處理報名的行政工作 (例如:
          確認手續、簽發收據、收款通知、捐款記錄及一切有關活動通訊等)；
          <br />
          　　c.
          防止和偵測利用本網站進行詐騙或濫用等行為，及讓第三者以本網站名義進行技術支援及後勤等工作。
          <br />
          3.
          為了執行上述事項，參賽者在報名網站內所提供的個人資料或會轉交給其他相關機構
          (例如: 報名網站)。
          <br />
          4.
          主辦方並不會出租、交換或售賣閣下自願在本網站提供所有可識別的個人資料，包括電郵、地址及聯絡電話等。
        </p>
        <h3>活動延期及修改</h3>

        <p>
          1. 根據下列原因，主辦單位有權更改、取消或縮短賽事：
          <br />
          　　a. 惡劣天氣
          (三號強風信號或更高的颱風警告信號，紅色或黑色暴雨警告信號)；
          <br />
          　　b.山泥傾瀉、泥石流、山火；
          <br />
          　　c. 抗議示威或活動路線被阻塞或封閉；
          <br />
          　　d.
          不可抗力事情，即發生超出主辦方所能控制的事件而導致活動出現不可能、不安全或不實際的情況
          <br />
          　　(不可抗力事件包括但不限於天災、戰爭、敵對行為、侵略、叛亂、革命、起義、暴動、騷亂、罷工、怠工、禁工或動亂、疾病的爆發以及恐怖主義行為和威脅)
          <br />
          2.
          主辦方有權將活動延期，唯延期之最終日期將視乎場地之供應和合作伙伴的意向而作出決定及公佈。
          <br />
          3.
          任何有關賽事之變更，主辦方有權作出任何修改並擁有解釋以上條款的權利。
          <br />
          4. 任何有關比賽/活動的臨時變動或更改，將以活動網站/大會官方Facebook
          Page公布為準，請密切留意各平台更新。
        </p>

        <h3>防疫應變措施</h3>

        <p>
          1. 活動需遵從香港特區政府公布的公共衛生相關政策。
          <br />
          2.
          如因香港特區政府公布的公共衛生相關政策令活動需要延期，主辦方將會在活動舉辦前一星期內透過大會官方Facebook
          Page、網站和電郵通知每位參賽者有關更改之安排及詳情。
          <br />
          3.
          應變措施將因應不同情況而主辦方有權作出任何更改或調整，詳情有待主辦方公佈。
        </p>
      </Content>
    </StyledSection12>
  );
};

export default Section12;
