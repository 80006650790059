import * as React from 'react';

import HeroSection2 from '../components/HeroSection2';
import Layout from '../components/Layout';
import Section10 from '../components/Section10';
import Section2 from '../components/Section2';
import Section3 from '../components/Section3';
import Section4 from '../components/Section4';
import Section5 from '../components/Section5';
import Section6 from '../components/Section6';
import Section7 from '../components/Section7';
import Section8 from '../components/Section8';
import Section9 from '../components/Section9';
import Section12 from '../components/Section12';
import Section13 from '../components/Section13';
import { OffsetAnchor } from '@digigear/elements';

const IndexPage = () => (
  <Layout>
    <HeroSection2 />
    <Section2 />
    <OffsetAnchor id="main" />
    <Section3 />
    <OffsetAnchor id="info" />
    <Section4 />
    <OffsetAnchor id="runnerspack" />
    <Section5 />
    <Section6 />
    <Section7 />
    <Section8 />
    <Section9 />
    <OffsetAnchor id="perks" />
    <Section10 />
    <OffsetAnchor id="tnc" />
    <Section12 />
    <OffsetAnchor id="partners" />
    <Section13 />
  </Layout>
);

export default IndexPage;
