import React from 'react';
import styled from 'styled-components';

import Section from './Section';
import Content from './Content';
import featureBg from '../images/nailoong/section_3.png';
import { AutoLayout } from '@digigear/elements';

import details_1 from '../images/nailoong/section_3_details_1.png';
import details_2 from '../images/nailoong/section_3_details_2.png';
import details_3 from '../images/nailoong/section_3_details_3.png';
import button_1 from '../images/nailoong/section_3_button_1.png';
import button_2 from '../images/nailoong/section_3_button_2.png';
import Button from './Button';

const StyledSection3 = styled(Section)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  padding: 0;
  margin-top: -1.2em;

  ${Content} {
    background: ${props => props.theme.palette.secondary.main};
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 80px 40px;
    color: ${props => props.theme.palette.primary.main};

    & h3 {
      margin: 0;
    }

    & h4 {
      font-weight: 300;
      font-size: 25px;
    }

    & h5 {
      font-weight: 300;
      font-size: 18px;
    }

    & p {
      font-weight: 300;
      font-size: 14px;
    }

    & .responsive-show {
      display: none;
    }

    @media screen and (max-width: 800px) {
      & .responsive-layout {
        flex-direction: column;
        margin-top: 24px;
        gap: 24px;
        justify-items: none;
      }

      & .responsive-reverse {
        flex-direction: column-reverse;
        gap: 24px;
        justify-items: none;
      }

      & .responsive-hidden {
        display: none;
      }

      & .responsive-show {
        display: initial;
      }
    }
  }
`;

const StyledBlock = styled.div`
  height: 100%;
  border-radius: 12px;
  padding: 12px 24px;

  background: #102574;
  color: white;
`;

const Section3 = () => {
  return (
    <StyledSection3>
      <img
        alt="hero"
        src={featureBg}
        style={{
          width: '100%',

          maxWidth: 'unset',
        }}
      />
      <Content alignItems="center">
        <h3>「中銀理財」FamilyMAX x ｢大家減齡｣呈獻：</h3>
        <h2>奶龍 Family & Friends 減齡 Get! Wet! Run!</h2>
        <AutoLayout
          className="responsive-layout"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            maxWidth: '1000px',
            width: '100%',
          }}
        >
          <div style={{ color: 'white', width: '100%' }}>
            <h4>全港首個奶龍路跑活動</h4>
            <p>
              喜歡奶龍的你千萬不要錯過全港首個奶龍主題的路跑活動！
              <br />
              活動將會在灣仔海濱長廊舉行，奶龍陪你欣賞沿途的維港景色。
              <br />
              場地配置多個拍照點，且有不同的市集攤位，歡迎大家盡情拍照玩樂！
              <br />
              <br />
              1公里賽事的跑手更首次加入水槍元素，齊與奶龍化身神槍手，挑戰自己。
              <br />
              而2公里和4公里則分為個人及朋友組報名參與，照顧不同能力的粉絲。
              <br />
              活動當日奶龍更會驚喜現身，親臨現場為大家打氣！
            </p>
          </div>
          <AutoLayout
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <img
              alt="details_3"
              src={details_3}
              style={{
                width: '273px',
                height: '130px',
              }}
            />
            <img
              alt="details_1"
              src={details_1}
              style={{
                width: '273px',
                height: '169px',
              }}
            />
          </AutoLayout>
        </AutoLayout>
        <AutoLayout
          className="responsive-layout"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            maxWidth: '1000px',
            width: '100%',
          }}
        >
          <img
            className="responsive-hidden"
            alt="details_2"
            src={details_2}
            style={{
              width: '414px',
              maxWidth: '80vw',
            }}
          />
          <Button
            href="https://www.klook.com/zh-HK/activity/112636-nailong-run/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt="button_1"
              src={button_1}
              style={{
                width: '431px',
                maxWidth: '80vw',
              }}
            />
          </Button>
        </AutoLayout>

        <AutoLayout
          className="responsive-layout responsive-reverse"
          direction="row"
          justifyContent="space-between"
          alignItems="top"
          style={{
            maxWidth: '1000px',
            width: '100%',
          }}
        >
          <Button
            href="https://www.facebook.com/Knighthoodevent"
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt="button_2"
              src={button_2}
              style={{
                width: '318px',
                maxWidth: '80vw',
              }}
            />
          </Button>
          <img
            className="responsive-show"
            alt="details_2"
            src={details_2}
            style={{
              width: '414px',
              maxWidth: '80vw',
            }}
          />
          <StyledBlock>
            <h5>
              報名成功者將獲得「奶龍 Family & Friends 減齡 Get! Wet! Run!
              」該組別的跑手包一份
            </h5>
            <p>
              基於安全理由，參加者年齡需限制如下：
              <br />
              1.
              3-11歲之1KM水槍探險戰參加者必須由成人陪同下方可進入跑道及參與賽事
              <br />
              2.
              5歲以下人士不能參加2公里之賽事，12歲以下參賽者必須由成人陪同下方可進入跑道及參與賽事
              <br />
              3. 12歲以下人士不能參加4公里之賽事
              <br />
              4. 18歲以下之參賽者，必須獲得家長或監護人同意方可報名。
            </p>
          </StyledBlock>
        </AutoLayout>
        <div
          style={{
            color: 'white',
            maxWidth: '80%',
            fontSize: '10px',
            fontWeight: 300,
            textAlign: 'center',
          }}
        >
          <p>
            *主辦單位保留更改活動場地之權利，活動場地將會以政府公告狀況適時調整，
            <br />
            若場地租借狀況有變動，大會將以網站、Facebook專頁公告，敬請密切留意。
          </p>
        </div>
      </Content>
    </StyledSection3>
  );
};

export default Section3;
