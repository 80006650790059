import React from 'react';
import styled from 'styled-components';

import featureBg from '../images/nailoong/section_2.png';
import Button from './Button';

const StyledSection2 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: hidden;
`;

const Section2 = () => {
  return (
    <StyledSection2>
      <img
        alt="hero"
        src={featureBg}
        style={{
          width: '100%',
          maxWidth: 'unset',
        }}
      />
      <Button
        style={{
          position: 'absolute',
          bottom: '2em',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '172px',
          height: '52px',
          fontSize: '22px',
          padding: 0,
        }}
        href="#runnerspack"
      >
        預覽選手包
      </Button>
    </StyledSection2>
  );
};

export default Section2;
